const Theme = {
  colors: {
    primary: '#D60000',
    text: '#808080',
  },
  fonts: {
    primary: `"Opens Sans", sans-serif`,
    heading: `"Barlow Condensed", sans-serif`
  }
}

export default Theme