// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-dodge-challenger-js": () => import("./../../../src/pages/dodge/challenger.js" /* webpackChunkName: "component---src-pages-dodge-challenger-js" */),
  "component---src-pages-dodge-charger-js": () => import("./../../../src/pages/dodge/charger.js" /* webpackChunkName: "component---src-pages-dodge-charger-js" */),
  "component---src-pages-dodge-durango-js": () => import("./../../../src/pages/dodge/durango.js" /* webpackChunkName: "component---src-pages-dodge-durango-js" */),
  "component---src-pages-dodge-index-js": () => import("./../../../src/pages/dodge/index.js" /* webpackChunkName: "component---src-pages-dodge-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ram-bighorn-js": () => import("./../../../src/pages/ram/bighorn.js" /* webpackChunkName: "component---src-pages-ram-bighorn-js" */),
  "component---src-pages-ram-index-js": () => import("./../../../src/pages/ram/index.js" /* webpackChunkName: "component---src-pages-ram-index-js" */),
  "component---src-pages-ram-laramie-js": () => import("./../../../src/pages/ram/laramie.js" /* webpackChunkName: "component---src-pages-ram-laramie-js" */),
  "component---src-pages-ram-limited-js": () => import("./../../../src/pages/ram/limited.js" /* webpackChunkName: "component---src-pages-ram-limited-js" */),
  "component---src-pages-ram-longhorn-js": () => import("./../../../src/pages/ram/longhorn.js" /* webpackChunkName: "component---src-pages-ram-longhorn-js" */),
  "component---src-pages-ram-rebel-js": () => import("./../../../src/pages/ram/rebel.js" /* webpackChunkName: "component---src-pages-ram-rebel-js" */),
  "component---src-pages-salon-js": () => import("./../../../src/pages/salon.js" /* webpackChunkName: "component---src-pages-salon-js" */),
  "component---src-pages-serwis-js": () => import("./../../../src/pages/serwis.js" /* webpackChunkName: "component---src-pages-serwis-js" */)
}

